<template>
    <div class="min-h-screen">
        <SectionLoading v-if="loading" text="Release loading..." />

        <div v-else class="max-w-xl mx-auto my-3">
            <div class="flex">
                <router-link to="/app" class="text-primary flex items-center space-x-1">
                    <ArrowLeftIcon size="16" />
                    <span>Back to all release</span>
                </router-link>
            </div>

            <form @submit.prevent="submitForm" class="mt-4">
                <h2 class="text-3xl font-semibold mb-4 text-black">Edit Release</h2>

                <!-- Cover Art -->
                <div class="mb-4" v-if="releaseData.cover_image_path">
                    <label class="text-sm block text-gray-700 font-medium mb-1">Cover Art</label>
                    <div class="flex items-center justify-between border border-gray-100/40 p-2 rounded-md">
                        <div class="flex items-center space-x-3">
                            <div class="w-12 overflow-hidden shadow-md shadow-gray-900 h-12 bg-gray-200 rounded">
                                <img :src="releaseData.cover_image_path" :alt="releasePayload.slug"
                                    class="object-cover w-full h-full" />
                            </div>

                            <div>
                                <p class="text-sm mb-1">
                                    {{ releaseData.slug }}
                                </p>
                            </div>
                        </div>

                        <button type="button" @click="releaseData.cover_image_path = null"
                            class="outline-none hover:text-primary focus:text-primary">
                            <XCircleIcon size="20" />
                        </button>
                    </div>
                </div>

                <div v-else class="mb-4">
                    <MUpload id="cover-art" path="release_cover" label="Cover Art" v-model="releasePayload.cover_photo"
                        accept="image/*" format="jpg, png, jpeg≈" info="Upload a square image of at least 3000 x 3000 pixels in JPG or PNG
              format." />
                </div>


                <!-- Release Title -->
                <div class="mb-4">
                    <label class="text-sm block text-gray-700 font-medium mb-1">Release Title</label>
                    <input type="text"
                        class="py-3 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        v-model="releasePayload.title" />
                </div>

                <!-- Title Language -->
                <div class="mb-4">
                    <label class="text-sm block text-gray-700 font-medium mb-1">Title Language</label>

                    <vs-select class="m-select" v-model="releasePayload.language" autocomplete>
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                            v-for="(item, index) in languages" />
                    </vs-select>
                </div>

                <!-- Genre -->
                <div class="mb-4">
                    <label class="text-sm block text-gray-700 font-medium mb-1">Genre</label>
                    
                    <vs-select class="m-select" v-model="releasePayload.genre" autocomplete>
                        <vs-select-item :key="index" :value="item.name" :text="item.name" v-for="(item, index) in genres" />
                    </vs-select>
                </div>

                <!-- Record Label -->
                <div class="mb-4">
                    <label class="text-sm block text-gray-700 font-medium mb-1">Record Label</label>
                    <input type="text"
                        class="py-3 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        v-model="releasePayload.label" />
                </div>

                 <!-- UPC Label -->
                <div class="mb-4">
                <label class="text-sm block text-gray-700 font-medium mb-1">UPC (Optional)</label>
                <input type="text"
                    class="py-3 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    v-model="releasePayload.upc" />
                </div>

                <!-- Delivery Options -->
                <div>
                    <p class="uppercase text-sm tracking-wider mt-5 mb-3">
                        Delivery Options
                    </p>

                    <!-- Stores -->
                    <div class="mb-4">
                        <label class="text-sm block text-gray-700 font-medium mb-2">Music Stores</label>

                        <div class="flex">
                            <vs-checkbox v-model="stores_all">
                                <span class="text-sm pl-1">Select All</span>
                            </vs-checkbox>
                        </div>

                        <div class="flex mb-3 mt-2 flex-wrap space-y-2">
                            <vs-checkbox v-model="releasePayload.stores" :vs-value="item.value"
                                v-for="(item, index) in stores" :key="index">
                                <span class="text-sm pl-1">{{ item.text }}</span>
                            </vs-checkbox>
                        </div>
                    </div>

                    <!-- Release Date -->
                    <div class="mb-4">
                        <label class="text-sm block text-gray-700 font-medium mb-1">Release Date</label>
                        <date-picker class="custom-datepicker" v-model="releasePayload.release_date" 
                        type="date" valueType="DD/MM/YYYY"
                            format="MMMM DD, YYYY" :disabled-date="disabledDatesByPlan"></date-picker>
                    </div>

                    <!-- Release Type -->
                    <div class="mb-4">
                        <label class="text-sm block text-gray-700 font-medium mb-1">Release type</label>

                        <vs-select class="m-select" v-model="releasePayload.type" autocomplete>
                            <vs-select-item :key="index" :value="item.value" :text="item.text"
                                v-for="(item, index) in releaseTypes" />
                        </vs-select>
                    </div>
                </div>

                <!-- Track List -->
                <div>
                    <p class="uppercase text-sm tracking-wider mt-5 mb-3">Track List</p>

                    <!-- Tracks -->
                    <div class="mb-4">
                        <div @click="isAddTrackOpen = true"
                            class="border-primary-100 flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-50">
                            <div class="flex items-center justify-center py-4 space-x-2">
                                <MusicIcon size="24" />
                                <p class="text-sm font-bold">Click to add track</p>
                            </div>
                        </div>
                        <!-- All Tracks  -->
                        <div class="mb-4 mt-2 space-y-2 flex flex-col">
                            <div v-for="(track, index) in add_tracks" :key="index"
                                class="space-y-2 bg-blue-50 p-3 rounded-md">
                                <div class="flex items-center justify-between">
                                    <div class="flex space-x-3 items-center">
                                        <DiscIcon class="w-6 h-6 text-primary" />

                                        <div class="flex items-center space-x-3">
                                            <div>
                                                <p class="text-sm mb-1 font-bold">{{ track.title }}</p>
                                                <p class="text-xs mb-0 text-gray-500">
                                                    {{ user.artist_name }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" @click="removeTrack(index)"
                                        class="outline-none hover:text-primary focus:text-primary text-gray-500">
                                        <XIcon class="w-6 h-6" />
                                    </button>
                                </div>
                            </div>

                            <!-- Existing tracks  -->
                            <div v-for="(track, index) in releaseData.tracks" :key="index"
                                class="space-y-2 bg-blue-50 p-3 rounded-md">
                                <div class="flex items-center justify-between">
                                    <div class="flex space-x-3 items-center">
                                        <DiscIcon class="w-6 h-6 text-primary" />

                                        <div class="flex items-center space-x-3">
                                            <div>
                                                <p class="text-sm mb-1 font-bold">{{ track.title }}</p>
                                                <p class="text-xs mb-0 text-gray-500">
                                                    {{ user.artist_name }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Actions -->
                <div class="flex justify-between mt-6">

                    <button type="submit" :disabled="isSubmitting"
                        class="bg-primary text-white py-3 px-4 mr-3 text-sm hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full mb-12">
                        <span class="flex items-center justify-between">
                            <span> Update release </span>
                            <Loading v-if="isSubmitting" size="25px" color="white" />
                            <ArrowRightIcon v-else size="16" />
                        </span>
                    </button>
                </div>
            </form>
        </div>

        <vs-sidebar position-right parent="body" default-index="1" class="track-modal" :click-not-close="true" spacer
            v-model="isAddTrackOpen">
            <div class="p-4 relative">
                <button class="flex items-center space-x-1 mb-3 outline-none hover:text-primary"
                    @click="isAddTrackOpen = false">
                    <XIcon size="24" />
                </button>

                <ReleaseTrackForm @submit="isAddTrackOpen = false" />
            </div>
        </vs-sidebar>
    </div>
</template>
  
<script>
import MUpload from "@/components/MUpload.vue";
import Loading from "@/components/Loading.vue";
import SectionLoading from "@/components/SectionLoading.vue";

import {
    ArrowLeftIcon,
    Edit3Icon,
    MusicIcon,
    XIcon,
    XCircleIcon,
    DiscIcon,
    ArrowRightIcon,
} from "vue-feather-icons";
import ReleaseTrackForm from "@/components/ReleaseTrackForm.vue";
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        MUpload,
        ArrowLeftIcon,
        Edit3Icon,
        MusicIcon,
        XIcon,
        ReleaseTrackForm,
        XCircleIcon,
        DiscIcon,
        Loading,
        ArrowRightIcon,
        DatePicker,
        SectionLoading
    },
    data() {
        return {
            isSubmitting: false,
            releaseData: {},
            tracks: [
                {
                    trackTitle: "",
                    trackNumber: "",
                    trackDuration: "",
                    trackLanguage: "english",
                },
            ],
            deliveryMethods: {
                download: false,
                streaming: false,
                physical: false,
            },
            loading: true,
            stores_all: false,
            languages: [
                { value: "afrikaans", text: "Afrikaans" },
                { value: "arabic", text: "Arabic" },
                { value: "czech", text: "Czech" },
                { value: "dutch", text: "Dutch" },
                { value: "english", text: "English" },
                { value: "french", text: "French" },
                { value: "german", text: "German" },
                { value: "hausa", text: "Hausa" },
                { value: "hindi", text: "Hindi" },
                { value: "igbo", text: "Igbo" },
                { value: "pidgin", text: "Pidgin" },
                { value: "russian", text: "Russian" },
                { value: "spanish", text: "Spanish" },
                { value: "yoruba", text: "Yoruba" },
            ],
            genres: [],
            releaseTypes: [
                { value: "album", text: "Album" },
                { value: "single", text: "Single" },
                { value: "ep", text: "EP" },
                { value: "compilation", text: "Compilation" },
                { value: "mixtape", text: "Mixtape" },
            ],
            releasePayload: {
                title: "",
                language: "",
                genre: "",
                upc: "",
                label: "",
                release_date: "",
                type: "",
                cover_photo: "",
                status: "pending",
                stores: [],
                tracks: [],
            },
            isAddTrackOpen: false,
            major_stores: [
                { value: "apple_music", text: "Apple Music" },
                { value: "spotify", text: "Spotify" },
                { value: "deezer", text: "Deezer" },
                { value: "tidal", text: "Tidal" },
            ],
            other_stores: [
                { value: "7digital", text: "7Digital" },
                { value: "amazon", text: "Amazon" },
                { value: "anghami", text: "Anghami" },
                { value: "audiomack", text: "Audiomack" },
                { value: "awa", text: "AWA" },
                { value: "boomplay", text: "Boomplay" },
                { value: "facebook_audio_library", text: "Facebook Audio Library" },
                { value: "gracenote", text: "Gracenote" },
                { value: "iheartradio", text: "iHearRadio" },
                { value: "mixcloud", text: "Mixcloud" },
                { value: "napster", text: "Napster" },
                { value: "netease_cloud_music", text: "NetEaseCloud Music" },
                { value: "peloton", text: "Peloton" },
                { value: "tencent", text: "Tencent" },
                { value: "tiktok", text: "Tiktok" },
                { value: "vevo", text: "VEVO" },
                { value: "youtube_music", text: "Youtube Music" }
            ]
        };
    },
    computed: {
        stores() {
            if (this.user?.subscription?.plan?.for.toLowerCase() === "free") {
                return this.major_stores
            }
            return [
                ...this.major_stores,
                ...this.other_stores];
        },
        user() {
            return this.$store.getters.user || {};
        },
        add_tracks() {
            return this.$store.getters.add_tracks || {};
        },
        fieldsValidated() {
            return (
                this.releasePayload.title &&
                this.releasePayload.language &&
                this.releasePayload.genre &&
                this.releasePayload.label &&
                // this.releasePayload.release_date &&
                this.releasePayload.type &&
                // this.releasePayload.cover_photo &&
                this.releasePayload.type
                // this.releasePayload.stores &&
                // this.releasePayload.tracks
            );
        },
    },
    watch: {
        stores_all(val) {
            if (val) {
                this.releasePayload.stores = this.stores.map((store) => store.value);
            } else {
                this.releasePayload.stores = [];
            }
        },
    },
    mounted() {
        this.getReleaseByID();
        this.getAllGenres()
    },
    methods: {
        getReleaseByID() {
            this.loading = true;
            const uuid = this.$route.params.id;
            let fetch = {
                path: `/artist/releases/single/${uuid}`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.releaseData = resp.data.data?.releases;


                    //         releasePayload: {
                    //     title: "",
                    //     language: "",
                    //     genre: "",
                    //     label: "",
                    //     release_date: "",
                    //     type: "",
                    //     cover_photo: "",
                    //     status: "pending",
                    //     stores: [],
                    //     tracks: [],
                    // },

                    this.releasePayload.title = this.releaseData.title
                    this.releasePayload.language = this.releaseData.language
                    this.releasePayload.genre = this.releaseData.genre
                    this.releasePayload.label = this.releaseData.label
                    this.releasePayload.release_date = this.releaseData.release_date
                    this.releasePayload.type = this.releaseData.type
                    this.releasePayload.upc = this.releaseData.upc

                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Release Info",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Release Info",
                            text: "Unable to get Release",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },
        disabledDatesByPlan(date) {
            const today = new Date();
            const selectedPlan = this.user?.subscription?.plan?.name.toLowerCase()

            if (selectedPlan === "melior start") {
                const minDate = new Date(today.getTime() + 21 * 24 * 60 * 60 * 1000);
                return date < minDate;
            } else if (selectedPlan === "melior growth") {
                const minDate = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
                return date < minDate;
            } else if (selectedPlan === "melior prime") {
                const minDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
                return date < minDate;
            } else if(selectedPlan === "melior - label services"){
                const minDate = new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000);
                return date < minDate;
            }

            return false; // Enable all other dates
        },
        removeTrack(index) {
            this.$store.commit("REMOVE_TRACK", index);
        },
        submitForm() {
            if (this.fieldsValidated) {
                this.isSubmitting = true;

                const data = {
                    ...this.releasePayload,
                    tracks: this.add_tracks,
                };

                let fetch = {
                    path: `/artist/releases/${this.releaseData?.uuid}`,
                    data,
                };

                this.$store
                    .dispatch("putRequest", fetch)
                    .then(() => {
                        this.isSubmitting = false;
                        this.$router.push("/");
                        this.$store.commit("RESET_ADD_TRACKS");

                        this.$toast.success(
                            "Release Updated",
                            "Your release has been updated successfully",
                            this.$toastPosition
                        );
                    })
                    .catch((err) => {
                        this.isSubmitting = false;
                        if (err.response) {
                            const { errors } = err.response.data;
                            this.errors = errors;
                            this.$toast.info(
                                "Release Error",
                                err.response.data.message,
                                this.$toastPosition
                            );
                        } else {
                            this.$toast.info(
                                "Error",
                                "Something went wrong, please try again",
                                this.$toastPosition
                            );
                        }
                    });
            } else {
                this.$toast.error("Please check again and fill all fields");
            }
        },
        getAllGenres() {
            let fetch = {
                path: `/artist/genre`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.genres = resp.data?.data || []
                })
        }
    },
};
</script>
  
<style lang="scss">
.track-modal.vs-content-sidebar {
    .vs-sidebar--background {
        z-index: 18000 !important
    }

    .vs-sidebar {
        background: white !important;
        max-width: 360px !important;
        z-index: 19000 !important
    }
}

.custom-datepicker {
    width: 100%;

    .mx-input {
        height: 58px;
    }
}
</style>
  